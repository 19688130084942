import React, { lazy, Suspense } from 'react'
import { ThemeProvider } from '@mui/material'
import { BrowserRouter, Route } from 'react-router-dom'
import {
    LogoutCallback,
    ProtectedRoute
} from '@thriveglobal/thrive-web-auth-core'
import {
    useTheme,
    ThriveFullscreenLoading
} from '@thriveglobal/thrive-web-leafkit'

const Home = lazy(() => import('../pages/Home'))
const TeamsReferral = lazy(() => import('../pages/TeamsReferral/TeamsReferral'))

export enum Routes {
    Home = '/profile',
    Profile = '/profile',
    Preferences = '/profile/preferences',
    Integrations = '/profile/integrations',
    SlackSuccess = '/profile/integrations/slack-success',
    SlackFailed = '/profile/integrations/slack-failed',
    HealthAndFitnessApps = '/profile/health-and-fitness-apps',
    Security = '/profile/security',
    Invite = '/profile/invite',
    TeamsReferral = '/profile/invite/teams/:id/:encodedRedirectUrl*',
    Logout = '/profile/logout'
}

const Router: React.FC = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<ThriveFullscreenLoading />}>
                    <ProtectedRoute
                        path={`${Routes.TeamsReferral}`}
                        component={TeamsReferral}
                    />

                    <ProtectedRoute
                        exact
                        path={`${Routes.Home}/:tabParam?/:subpath?`}
                        component={Home}
                    />
                    <Route path={Routes.Logout} component={LogoutCallback} />
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
